.datePickerContainer {
    display: flex;
}
.datePicker {
    margin-right: 1em;
}

.nextPageRetrieveButton {
    margin-left: 1em;
}

.nextPageRetrieveFlasbar {
    margin-bottom: 1em;
}

.errorFlashbar {
    margin-bottom: 1em;
}