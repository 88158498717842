.redriveComponent {
    margin: 0.5em auto 0em auto;
    width: 70%;
}

.content {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    margin: 1em auto auto auto;
}

.label {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 10em;
}

.field {
    display: flex;
    margin-bottom: 1em;
    width: 100%;
}

.button {
    flex-shrink: 0;
    margin-left: 1em;
}

.statusFlasbar {
    width: 100%;
    margin-bottom: 1em;
}

.textArea {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 0.2em;
}

.textAreaNote {
    display: flex;
    font-size: smaller;
    color: gray;
    line-height: 1.2em;
}

.stackSelector {
    width: 100%;
    margin-bottom: 1em;
}

.redriveSelector {
    margin-bottom: 1em;
}