.container {
    width: 70%;
    margin: 0.5em auto 1em auto;
    display: flex;
    height: 14.8em;
    justify-content: center;
}

.header {
    height: 1.5em;
    font-weight: bolder;
    font-size: 1.25em;
}