.tableContainer {
    margin: 2em 0em 0em 0em;
    font-size: x-small;
}

.navigation {
    margin-top: 2.8em;
    margin-left: 0.1em;
}

.tableLabel {
    text-align: left;
}

.tableLabelNormal {
    @extend .tableLabel;
    font-size: small;
    font-weight: bold;
}

.tableLabelSelected {
    @extend .tableLabel;
    font-size: 13px;
    font-weight: bolder;
}

.table {
    margin: 0em 0.5em 0.5em 0.5em;
}