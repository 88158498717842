.button {
    flex-shrink: 0;
    text-align: center;
    width: 8em;
}

.searchBarRow {
    display: flex;
    margin-bottom: 0.7rem;
    height: 2.5em;
}

.label {
    display: flex;
    align-items: center;
    width: 11em;
    flex-shrink: 0;
}

.input {
    width: 100%;
    margin-right: 0.1rem;
    // this is a fix for polaris adding 4px padding for some reason
    // I'm not sure if that value is calculated and might change between
    // environments
    margin-top: -4px;
    min-width: 17em;
}